import React from "react";
import "./singlenewspage.css";
import { useParams } from "react-router";
import { motion } from "framer-motion";
import { Headerao } from "../../../components";
import { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  where,
  query,
  limit,
} from "firebase/firestore";
import { db, storage } from "../../../firebase-config";
import { ref, getDownloadURL } from "firebase/storage";
import Skeleton from "@mui/material/Skeleton";
import Carousel from "../../../components/carousel/Carousel";
const containerVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const SingleNewsPage = () => {
  const [aktualnosc, setAktualnosc] = useState("");
  const [images, setImages] = useState("");
  let { newsId } = useParams();

  useEffect(() => {
    const aktualnosciRef = collection(db, "aktualnosci");
    const q = query(
      aktualnosciRef,
      where("id", "==", newsId),
      limit(1)
    );
    let aktualnosc;

    const fetchData = async () => {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        aktualnosc = doc.data();
      });
      setAktualnosc(aktualnosc);

      let images = aktualnosc.imageRef;
      // Function to get the download URL of an image from Firebase Storage
      async function getImageDownloadURL(storageRef) {
        try {
          const url = await getDownloadURL(storageRef);
          return url;
        } catch (error) {
          throw error;
        }
      }

      // Function to retrieve an array of image URLs from Firebase Storage paths
      async function getImageUrlsFromFirebase(imagePaths) {
        // Create an array of promises to fetch the URLs
        const urlPromises = imagePaths.map(async item => {
          const storageRef = ref(storage, item);
          try {
            const url = await getImageDownloadURL(storageRef);
            return url;
          } catch (error) {
            console.error(
              `Error getting download URL for ${item}: ${error.message}`
            );
            return null; // If an error occurs, return null to maintain the original order
          }
        });

        try {
          // Wait for all the promises to resolve
          const imageUrls = await Promise.all(urlPromises);
          return imageUrls;
        } catch (error) {
          console.error(
            "Error retrieving image URLs:",
            error.message
          );
          return [];
        }
      }

      // Call the function to get an array of image URLs
      getImageUrlsFromFirebase(images)
        .then(imageUrls => {
          setImages(imageUrls);
          // The 'imageUrls' array contains the URLs of the images stored in Firebase Storage
          // You can use this array as needed here (e.g., to display images on a webpage)
        })
        .catch(error => {
          console.error(
            "Error retrieving image URLs:",
            error.message
          );
        });
    };
    fetchData();
  }, [newsId]);

  return (
    <motion.div
      className="images"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <Headerao title={"Aktualności"} />
      <div className="news parafia">
        {!aktualnosc ? (
          <div className="news-content parafia-container skeleton">
            <div className="news-content-top">
              <div className="news-content-top-date single-news-date">
                <Skeleton
                  variant="text"
                  width={105}
                  sx={{ fontSize: "1.1em" }}
                />
              </div>
              <div className="title skeleton">
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={99}
                />
              </div>
            </div>
            <Skeleton
              variant="rectangular"
              width={"80%"}
              height={300}
            />
          </div>
        ) : (
          <div className="news-content parafia-container">
            <div className="news-content-top">
              <div className="news-content-top-date single-news-date">
                <p>{aktualnosc.day}-</p>
                <p>{aktualnosc.month}-</p>
                <p>{aktualnosc.year}</p>
              </div>
              <div className="title">
                <h2>{aktualnosc.title}</h2>
                <div className="title-separator"></div>
              </div>
            </div>
            {images.length > 1 ? (
              <div className="news-carousel">
                <Carousel images={images} />
              </div>
            ) : (
              <img
                className="news-single-img"
                src={images[0]}
                alt="aktualność"
              ></img>
            )}
            <div className="news-content-text">
              {aktualnosc.content}
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default SingleNewsPage;
