import React, { useEffect, useState } from "react";
import "./slideshow.css";
import { AnimatePresence, motion } from "framer-motion";

const Slideshow = () => {
  const nextImg = () => {
    setSrc(Math.floor(Math.random() * 70));
  };

  const [src, setSrc] = useState(Math.floor(Math.random() * 72));

  useEffect(() => {
    const imgInterval = setInterval(nextImg, 5000);
    return () => clearInterval(imgInterval);
  });

  return (
    <AnimatePresence>
      <motion.img
        key={src}
        src={`pics/${src}.jpg`}
        alt="slideshow homepage"
        className="homepage-header-img"
        initial={{ x: 300, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ ease: "easeOut", duration: 1 }}
        exit={{ x: -300, opacity: 0 }}
      />
    </AnimatePresence>
  );
};

export default Slideshow;
