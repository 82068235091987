import z1 from "./resources/1.jpg";
import z2 from "./resources/2.jpg";
import z3 from "./resources/3.jpg";
import z4 from "./resources/4.jpg";
import z5 from "./resources/5.jpg";
import z6 from "./resources/6.jpg";
// import z7 from "./resources/7.jpg";
// import z8 from "./resources/8.jpg";
// import z9 from "./resources/9.jpg";
// import z10 from "./resources/10.jpg";

export const images = [z1, z2, z3, z4, z5, z6];
