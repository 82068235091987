import React from "react";
import "./formOgloszenia.css";
import { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../../firebase-config";

const FormOgloszenia = () => {
  const [preview, setPreview] = useState([
    "Podgląd ogłoszeń - edytuj powyższe pole.",
  ]);
  const [input, setInput] = useState("");
  const [autoDate, setAutoDate] = useState(true);
  const [loading, setLoading] = useState(false);
  const [ogloszenia, setOgloszenia] = useState({
    title: "",
    text: preview,
    date: Date.now(),
    day: String(new Date().getDate()).padStart(2, "0"),
    month: String(new Date().getMonth() + 1).padStart(2, "0"),
    year: new Date().getFullYear(),
  });

  function handleInputChange(event) {
    console.log(ogloszenia);
    const newValue = event.target.value;
    setInput(newValue);
    const newPreview = event.target.value
      .split(/^[0-9]+\.\s/gm)
      .slice(1);
    setPreview(newPreview);
    setOgloszenia(prevState => ({
      ...prevState,
      text: newPreview,
    }));
  }

  const onSubmit = e => {
    e.preventDefault();
    const addOgloszenia = async () => {
      try {
        setLoading(true);
        const docRef = await addDoc(
          collection(db, "ogloszenia"),
          ogloszenia
        );
        console.log("Document written with ID: ", docRef.id);
        setPreview([
          `Ogłoszenia "${ogloszenia.title}" zostały dodane. Edytuj powyższe pole aby dodać kolejne`,
        ]);
        setInput("Wklej kolejne ogłoszenia");
        setAutoDate([true]);
        setOgloszenia({
          title: "",
          date: Date.now(),
          text: preview,
          day: String(new Date().getDate()).padStart(2, "0"),
          month: String(new Date().getMonth() + 1).padStart(2, "0"),
          year: new Date().getFullYear(),
        });
        alert("Ogłoszenia zostały dodane!");
      } catch (e) {
        console.error("Error adding document: ", e);
      } finally {
        setLoading(false);
      }
    };
    addOgloszenia();
  };

  return (
    <div>
      <div className="form-ogloszenia">
        <h1 className="form-ogloszenia-margin">Dodaj ogłoszenia:</h1>
        <form onSubmit={onSubmit} className="form-ogloszenia-form">
          <h2>Tytuł: </h2>
          <input
            type="text"
            placeholder="Tytuł ogłoszeń"
            value={ogloszenia.title}
            onChange={e =>
              setOgloszenia(prevState => ({
                ...prevState,
                title: e.target.value,
              }))
            }
          />
          <h2>Data: </h2>
          <div className="form-ogloszenia-data">
            <label>Dzisiejsza data</label>
            <input
              className="form-ogloszenia-check-box"
              type="checkbox"
              checked={autoDate}
              onChange={() => {
                setOgloszenia(prevState => ({
                  ...prevState,
                  date: Date.now(),
                  day: new Date().getDate(),
                  month: new Date().getMonth() + 1,
                  year: new Date().getFullYear(),
                }));
                setAutoDate(!autoDate);
                console.log(ogloszenia.day);
              }}
            />
            <input
              className="form-ogloszenia-numer"
              type="date"
              placeholder="Data"
              disabled={autoDate}
              value={`${ogloszenia.year}-${String(
                ogloszenia.month
              ).padStart(2, "0")}-${String(ogloszenia.day).padStart(
                2,
                "0"
              )}`}
              onChange={e => {
                let [y, m, d] = e.target.value
                  .split("-")
                  .map(value => parseInt(value, 10));
                const currentDate = new Date();
                const h = currentDate.getHours();
                const mn = currentDate.getMinutes();
                const s = currentDate.getSeconds();
                setOgloszenia(prevState => ({
                  ...prevState,
                  date: new Date(y, m - 1, d, h, mn, s).getTime(),
                  year: y,
                  month: m,
                  day: d,
                }));
                console.log(
                  new Date(y, m - 1, d, h, mn, s).getTime()
                );
                console.log("d: ", d, "m: ", m - 1, "y: ", y);
              }}
            />
          </div>
          <h2>Ogłoszenia:</h2>
          <textarea
            className="ogloszenia-input"
            type="text"
            placeholder="Wklej ogłoszenia tutaj"
            rows="5"
            cols="50"
            value={input}
            onChange={e => handleInputChange(e)}
          />
          <h2>Podgląd:</h2>
          <div className="ogloszenia-container-current ogloszenia-container-box">
            <div className="ogloszenia-container-current-top">
              <p className="ogloszenia-container-current-date">
                {ogloszenia.day} / {ogloszenia.month} /{" "}
                {ogloszenia.year}
                &nbsp;
              </p>
              <h2 className="form-title">{ogloszenia.title}</h2>
            </div>
            <ul>
              {preview.map((item, index) => (
                <li key={index} className="ogloszenia-single-text">
                  <p className="ogloszenia-single-par">{item}</p>
                </li>
              ))}
            </ul>
          </div>
          <button
            className="form-ogloszenia-submit-button"
            disabled={loading}
          >
            {loading ? "Wysyłanie..." : "Wyślij ogłoszenia"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default FormOgloszenia;
