import React, { useEffect, useState } from "react";
import "./forms.css";
import FormOgloszenia from "./formOgloszenia/FormOgloszenia";
import FormAktualnosci from "./formAktualnosci/FormAktualnosci";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const Forms = () => {
  const [email, setEmail] = useState("");
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        setEmail(user.email);
      } else {
        console.log("Sign in");
        setEmail("");
      }
    });
    return unsubscribe;
  });

  const [type, setType] = useState("ogloszenia");

  return (
    <div className="forms">
      {email ? (
        <>
          <div className="forms-email">{email}</div>
          {type === "aktualnosci" ? (
            <>
              <button
                className="forms-button"
                onClick={() => setType("ogloszenia")}
              >
                ogłoszenia
              </button>
              <FormAktualnosci />
            </>
          ) : (
            <>
              <button
                className="forms-button"
                onClick={() => setType("aktualnosci")}
              >
                aktualnosci
              </button>
              <FormOgloszenia />
            </>
          )}
        </>
      ) : (
        <div>Sign in</div>
      )}
    </div>
  );
};

export default Forms;
