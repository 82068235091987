import React from "react";
import "./content.css";
import fneri from "../resources/fneri.png";
import { motion } from "framer-motion";

const Content = () => {
  return (
    <div className="content">
      <img src={fneri} alt="Filip Neri" />
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ ease: "easeOut", duration: 1 }}
        viewport={{ amount: 0.2 }}
        className="content-hours"
      >
        <div className="content-hours-title">
          <h2>Porządek mszy świętych</h2>
          <div className="title-separator"></div>
        </div>
        <div className="content-hours-lists">
          <div className="content-hours-list">
            <h3>Niedziela</h3>
            <ul>
              <li>
                <span className="content-hours-list-span">7:30</span>{" "}
                - dla wszystkich
              </li>
              <li>
                <span className="content-hours-list-span">9:00</span>{" "}
                - dla wszystkich
              </li>
              <li>
                <span className="content-hours-list-span">11:00</span>{" "}
                - dla dzieci
              </li>
              <li>
                <span className="content-hours-list-span">12.30</span>{" "}
                - dla wszystkich
              </li>
              <li>
                <span className="content-hours-list-span">18.00</span>{" "}
                - dla wszystkich
              </li>
              <li>
                <span className="content-hours-list-span">19.30</span>{" "}
                - Msza święta "ostatniej szansy"
              </li>
            </ul>
          </div>
          <div className="content-hours-list">
            <h3>Dni powszednie</h3>
            <ul>
              <li>
                <span className="content-hours-list-span">7:00</span>
              </li>
              <li>
                <span className="content-hours-list-span">18:00</span>
              </li>
              <li>
                <span className="content-hours-list-span">18:30</span>
                {""}
                (Wrzesień - Czerwiec)
              </li>
            </ul>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Content;
