import React, { useState } from "react";
import "./formAktualnosci.css";
import { collection, addDoc } from "firebase/firestore";
import { db, storage } from "../../../firebase-config";
import { ref, uploadBytes } from "firebase/storage";
import { v4 } from "uuid";
import uniqid from "uniqid";
import imageCompression from "browser-image-compression";

const FormAktualnosci = () => {
  const [post, setPost] = useState({
    title: "",
    content: "",
    img: [],
    imgThumb: "",
    vertical: false,
  });
  console.log(post);

  const [loading, setLoading] = useState(false);

  const onChange = async e => {
    try {
      setLoading(true);
      const files = Object.values(e.target.files);
      const compressedImages = await Promise.all(
        files.map(async file => {
          return compressImage(file, 1500, 0.28); // Adjust the size limit as needed
        })
      );
      setPost(prevState => ({
        ...prevState,
        img: compressedImages,
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onChange2 = async e => {
    try {
      setLoading(true);
      const file = e.target.files[0];
      /*       const compressedThumbnail = await compressImage(
        file,
        600,
        0.23
      );
      console.log(compressedThumbnail); */
      setPost(prevState => ({
        ...prevState,
        imgThumb: file,
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const compressImage = async (file, maxWidth, maxSize) => {
    try {
      console.log(
        "Original image instanceof Blob",
        file instanceof Blob
      );
      console.log(
        `Original image size ${file.size / 1024 / 1024} MB`
      );

      const options = {
        maxSizeMB: maxSize,
        maxWidthOrHeight: maxWidth,
        useWebWorker: true,
      };

      const compressedImage = await imageCompression(file, options);

      console.log(
        "Compressed image instanceof Blob",
        compressedImage instanceof Blob
      );
      console.log(
        `Compressed image size ${
          compressedImage.size / 1024 / 1024
        } MB`
      );

      return compressedImage;
    } catch (error) {
      console.error("Error compressing image:", error);
      return null;
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setLoading(true);
      const paths = [];

      for (let i = 0; i < post.img.length; i++) {
        const path = `aktualnosci/${post.img[i].name + v4()}`;
        const imageRef = ref(storage, path);
        console.log("test");
        await uploadBytes(imageRef, post.img[i]).then(snapshot => {
          console.log(`image ${post.img[i].name} added`);
        });
        paths.push(path);
      }

      const pathThumb = `aktualnosci/${post.imgThumb.name + v4()}`;
      const thumbRef = ref(storage, pathThumb);
      await uploadBytes(thumbRef, post.imgThumb).then(snapshot => {
        console.log("thumbnail added");
      });

      const date = new Date();

      const docRef = await addDoc(collection(db, "aktualnosci"), {
        id: uniqid(),
        title: post.title,
        content: post.content,
        vertical: post.vertical,
        imageRef: paths,
        thumbRef: pathThumb,
        date: Date.now(),
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      });
      console.log("Document written with ID: ", docRef.id);
      // Reset post to default values after successful submission
      e.target.reset();
      setPost({
        title: "",
        content: "",
        img: [],
        imgThumb: "",
        vertical: false,
      });
      alert("Post został dodany!");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form-ogloszenia">
      <h1 className="form-ogloszenia-margin">Dodaj do aktualności</h1>
      <form
        autoComplete="off"
        onSubmit={handleSubmit}
        className="form-ogloszenia-form"
      >
        <h2>Tytuł aktualności</h2>
        <input
          type="text"
          placeholder="Tytuł"
          required
          className="form-input"
          id="search"
          value={post.title}
          onChange={e =>
            setPost(prevState => ({
              ...prevState,
              title: e.target.value,
            }))
          }
        />
        <h2>Zdjęcie miniaturka:</h2>
        <input
          type="file"
          placeholder="upload file"
          className="form-input-upload-file"
          id="img"
          onChange={onChange2}
        />
        <h2>Zdjęcia:</h2>
        <input
          type="file"
          placeholder="upload file"
          className="form-input-upload-file"
          id="img"
          onChange={onChange}
          multiple
        />
        <div className="form-ogloszenia-data">
          <label>Rodzaj miniaturki: </label>
          <button
            type="button"
            onClick={() => {
              setPost(prevState => ({
                ...prevState,
                vertical: !post.vertical,
              }));
            }}
          >
            {post.vertical ? "Pionowa" : "Pozioma"}
          </button>
        </div>

        <textarea
          type="text"
          placeholder="Tekst"
          required
          className="form-input"
          id="search"
          value={post.content}
          onChange={e =>
            setPost(prevState => ({
              ...prevState,
              content: e.target.value,
            }))
          }
        />
        <button type="submit" disabled={loading}>
          {loading ? "Czekaj..." : "Wyślij"}
        </button>
      </form>
      {/* <img src={post.img.img} alt="uploadedImage"></img> */}
    </div>
  );
};

export default FormAktualnosci;
